import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { NavLink, useHistory } from 'react-router-dom'


import { HttpContext } from '../context/HttpContext'
import { useToasts } from 'react-toast-notifications'

import logo from '../assets/images/logo-action.svg'
import eMailIcon from '../assets/images/svg/emailicon.svg'

export const PasswordRecoveryPage = () => {

    const { addToast } = useToasts()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)
    const history = useHistory()

    const { loading, request } = useHttp()

    const [form, setForm] = useState({
        email: ''
    })

    const changeHandler = event => {
        setForm({ ...form, [event.target.name] : event.target.value })
    }

    const recoverHandler = async (e) => {
        e.preventDefault()
        if(form.email.length === 0) {
            addToast('Введите Email, указанный при регистрации!', { appearance : 'warning', autoDismiss : true })
            return
        }
        try {
            const response = request( host.url+'/password/email', 'POST', { ...form })
            response.then(data => {
                console.log(data)
                if(data.result === true) {
                    addToast(data.message, { appearance : 'success', autoDismiss : true })
                    setForm({email : ''})
                    history.push('/')
                } else {
                    addToast(data.message, { appearance : 'warning', autoDismiss : true })
                }
            })
        } catch(e) { console.log(e) }
    }


    return(
        <section className="enter">
            <div className="enter__wrap">
                <div className="enter__inner">
                    <div className="form-box">
                        <object className="logo-view" type="image/svg+xml" width="170" height="46" data={logo}></object>   
                        <span className="enter__value">Восстановление пароля</span>
                        <span className="enter__value" style={{ fontWeight : 'normal', fontSize : '12px', paddingTop : '10px' }}>Введите Email, который был указан при регистрации</span>
                        <form className="enter-form">
                            <div className="enter-form__wrap">
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="email" name="email" placeholder="youremail@site.com" onChange={changeHandler}/>
                                </div>
                                
                                
                            </div>
                            <button 
                                className="action-btn"
                                onClick={(e) => recoverHandler(e)}
                                disabled={ loading ? true : false }
                                style={{ cursor : loading && 'progress' }}
                                >Сбросить пароль</button>
                        </form>
                        <p className="question-user">
                            Еще не зарегистрированы?  <NavLink to="/register">Регистрация</NavLink>
                        </p>
                        <p className="question-user">
                            <a href="https://instantbooster.ru" target="_blank">instantbooster.ru</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}