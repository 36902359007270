import React from 'react'
import { NavLink } from 'react-router-dom'
import navIcon1 from '../assets/svg/nav-icon1.svg'
import navIcon2 from '../assets/svg/nav-icon2.svg'
import navIcon3 from '../assets/svg/nav-icon3.svg'
import navIcon4 from '../assets/svg/nav-icon4.svg'
import navIcon5 from '../assets/svg/nav-icon5.svg'
import navIcon6 from '../assets/svg/nav-icon6.svg'
import navIcon7 from '../assets/svg/nav-icon7.svg'
import navRef from '../assets/svg/icon-ref.svg'
import navRefund from '../assets/svg/icon-refund.svg'
import navMessages from '../assets/svg/icon-messages.svg'
import { useData } from '../context/DataContext'

export const NavPanel = ({notificationHeight}) => {

    const appData = useData()

    const menuClickHandler = () => {

        appData.setData({...appData.app, mobileMenu : false})

    }

    return(
        <div className={"wrapper-nav-panel"}>
            <aside className={appData.app.mobileMenu ?  "navigation-panel menu-opened" : "navigation-panel"} style={{ top : 76 +  notificationHeight +'px'}}>
                <nav className="navigation">
                    <ul className="navigation-list">
                        <li className="navigation-list__item">
                            <NavLink className="" to="/order" onClick={menuClickHandler}>
                                <span className="navigation-list__link">
                                    <img className="navigation-list__icon" src={navIcon1} alt="Заказ" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Новый заказ</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/orders" onClick={menuClickHandler}>
                                <span className="navigation-list__link">
                                    <img className="navigation-list__icon" src={navIcon2} alt="Список" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Список заказов</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/payment" onClick={menuClickHandler}>
                                <span className="navigation-list__link"> 
                                    <img className="navigation-list__icon" src={navIcon3} alt="Счет" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Пополнить счет</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item" onClick={menuClickHandler}>
                            <NavLink to="/transactions">
                                <span className="navigation-list__link" >
                                    <img className="navigation-list__icon" src={navIcon4} alt="Журнал" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Журнал транзакций</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item" onClick={menuClickHandler}>
                            <NavLink to="/referal">
                                <span className="navigation-list__link" >
                                    <img className="navigation-list__icon" src={navRef} alt="Журнал" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Реферальная программа</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item" onClick={menuClickHandler}>
                            <NavLink to="/payout">
                                <span className="navigation-list__link" >
                                    <img className="navigation-list__icon" src={navRefund} alt="Журнал" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Вывод средств</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/prices" onClick={menuClickHandler}>
                                <span className="navigation-list__link ">
                                    <img className="navigation-list__icon" src={navIcon5} alt="Цены" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Цены</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/news" onClick={menuClickHandler}>
                                <span className="navigation-list__link">
                                    <img className="navigation-list__icon" src={navMessages} alt="Поддержка" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Сообщения</span>
                                </span>
                            </NavLink>    
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/support" onClick={menuClickHandler}>
                                <span className="navigation-list__link">
                                    <img className="navigation-list__icon" src={navIcon6} alt="Поддержка" width="18"
                                        height="20" />
                                    <span className="navigation-list__name">Служба поддержки</span>
                                </span>
                            </NavLink>
                            
                        </li>
                        <li className="navigation-list__item">
                            <NavLink to="/faq" onClick={menuClickHandler}>
                                <span className="navigation-list__link">
                                    <img className="navigation-list__icon" src={navIcon7} alt="FAQ" width="18" height="20"/>
                                    <span className="navigation-list__name">FAQ</span>
                                </span>
                            </NavLink>
                            
                        </li>
                    </ul>
                </nav>
                <div className="information">
                    <NavLink to="/support" onClick={menuClickHandler}>
                        <span className="information__link" >
                            <span className="information__text">Служба поддержки</span>
                            {/* <span className="information__icon">0</span> */}
                        </span>
                    </NavLink>
                    
                    <ul className="information-list">
                        <li className="information-list__item">
                            <span>E-mail: </span>
                            <a href="mailto:support@namesite.ru">support@instantbooster.ru</a>
                        </li>
                        <li className="information-list__item">
                            <span>Часы работы: </span>
                            <span>Круглосуточно</span>
                        </li>
                    </ul>
                </div>
            </aside>
        </div>
    )
}