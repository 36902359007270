import React from "react"

export const TicketItem = ({created, topic, id, message, ticket_id, support_answer, status, setShowAnswer, setOpenedTicket, answer}) => {
    
    const answerHandler = () => {
        setShowAnswer(true)
        setOpenedTicket({
            ticket_id : ticket_id,
            topic : topic,
            message : message,
            created : created,
            support_answer : support_answer,
            answer : answer
        })
    }

    return(
        <tr key={id} onClick={() => answerHandler(id)} style={{ cursor : 'pointer' }}>
            <td>{ticket_id}</td>
            <td>{created}</td>
            <td>{topic}</td>
            <td>
                <span className="await-reply">
                    {status === 'new' && 'Ожидает ответа'}
                    {status === 'closed' && 'Отвечено'}
                </span>
            </td>
        </tr>
    )
}