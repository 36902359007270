import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import logo from '../assets/images/logo-action.svg'
import exitIcon from '../assets/images/svg/exit.svg'
import customizationIcon from '../assets/images/svg/customization.svg'
import letterIcon from '../assets/svg/letter.svg'
import personI from'../assets/images/svg/person-i.svg'
import plusIcon from '../assets/svg/plus.svg'
import arrowSmall from '../assets/images/svg/arrow-small.svg'
import dialogIcon from '../assets/svg/dialog.svg'
import langIcon from '../assets/svg/language.svg'
import burger from '../assets/images/svg/burger.svg'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'
import { useData } from '../context/DataContext'
import { EmailVerificationNotice } from './EmailVerificationNotice'

import avatar from '../assets/images/profile.png'


export const Header = ({ menus, notificationHeight, setNotificationHeight }) => {

    const [actionMenu, setActionMenu] = useState(false)
    const [actionMenuMob, setActionMenuMob] = useState(false)
    const host = useContext(HttpContext)

    const { request } = useHttp()

    const actMenu = useRef( null )
    const personRef = useRef( null )
    
    const appData = useData()

    const menuHandler = () => {
        actionMenu ? setActionMenu(false) : setActionMenu(true)
    }

    const menuHandlerMob = () => {
        appData.setData({...appData.app, mobileMenu : false})
        actionMenuMob ? setActionMenuMob(false) : setActionMenuMob(true)
    } 

    const history = useHistory()
    const auth = useContext(AuthContext)
    const [balance, setBalance] = useState()

    const logoutHandler = async () => {
        auth.logout()
        history.push('/')
        try {
            const response = request( host.url+'/logout', 'POST', { }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` })
            response.then(data => {})
        } catch(e) { console.log(e) }
    }

    const getBalance = async () => {
        if(auth.userId !== null){
            if(balance === undefined) {
                try {
                    const response = request( host.url+'/balance', 'POST', { client_id : auth.userId }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
                    response.then(data => {
                        appData.setData({...appData.app, balance : parseFloat(data.data)})
                        setBalance(data.data)
                    })
                  } catch(e) {
                    console.log(e);
                  }
            }
        }
        
    }

    useEffect(() => {
        getBalance()
    }, [auth.userId])

    const mobileMenuHandler = () => {
        appData.app.mobileMenu ? appData.setData({...appData.app, mobileMenu : false}) : appData.setData({...appData.app, mobileMenu : true})    
    }

    useEffect(() => {
        appData.setData({...appData.app, mobileMenu : false})
        setActionMenuMob(false)
        // const onClick = e => actMenu.current.contains(e.target) ||  setActionMenu(false)
        // document.addEventListener('click', onClick)
        // return () => document.removeEventListener('click', onClick)
        // const onClick = (e) => {
            
        //     if(personRef.current !== null && actMenu.current !== null){
                
        //     }
        //     if(!personRef.current.contains(e.target) || !actMenu.current.contains(e.target)){

        //     } else {
        //         setActionMenu(false)
        //     }

        //     // console.log(personRef)
            
        // }
        // document.addEventListener('click', onClick)
        // return () => document.removeEventListener('click', onClick)
    }, [ menus ])


    /**
     * Проверка на новые сообщения от службы поддержки
     */
    const MINUTE_MS = 60000

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Logs every minute')
        }, MINUTE_MS)

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
        

    return(
        <>
        
        {/* <EmailVerificationNotice setNotificationHeight={setNotificationHeight} /> */}

        <header className="header-page" style={{ top : notificationHeight }}>
            <div className="header-page__row">
                <button className="open-menu">
                    <img src={burger} alt="Открыть меню" onClick={mobileMenuHandler}/>
                </button>
                <NavLink className="logo" to="/order">
                    <object type="image/svg+xml" width="170" height="46" data={logo}></object>      
                </NavLink>
                
                <div className="action-bar">
                        {/* <div className="language">
                            <span className="language__select">Русский</span>
                            <img className="language__icon" src={langIcon} width="19" height="20" alt="Выбраный язык"/>
                        </div> */}
                        <ul className="action-list">
                            <li className="action-list__item">
                                <NavLink to="/support">
                                    <img src={dialogIcon} alt="Коментарий" width="20" height="20"/>
                                </NavLink> 
                            </li>
                            <li className="action-list__item">
                                <NavLink to="/news">
                                    <img src={letterIcon} alt="Письмо" width="20" height="20"/>
                                </NavLink>
                            </li>
                            <li className="action-list__item action-list__item--mobile" onClick={menuHandlerMob}>
                                <a href="#" >
                                    <img src={personI} alt="Профиль" width="20" height="20"/>
                                </a>
                            </li>
                        </ul>
                    <div className="mobile-person-bar">
                        <div className={actionMenuMob ? "wrapper-mobile-box visible-mobile" : "wrapper-mobile-box"}>
                            <div className="balance">
                                <span className="balance__label">Баланс:</span>
                                {/* <span className="balance__value">{balance}</span> */}
                                <span className="balance__value">{appData.app.balance}</span>
                                <span className="balance__currency">₽</span>
                                <NavLink to="/payment">
                                    <span className="balance__add" href="/payments">
                                        <img src={plusIcon} alt="добавить" width="20" height="20"/>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="person" ref={personRef} onClick={menuHandler} style={{ cursor : 'pointer' }}>
                                <div className="person__wrap" >
                                    <div className="person__avatar avatar">
                                        <img src={avatar} />
                                        {/* <img src="https://www.vokrug.tv/pic/person/b/7/7/c/b77c952225d1a2326da447e4076b8d82.jpg" alt="Фото"/> */}
                                    </div>
                                    <div className="person__name">
                                        <span>{auth.userName}</span>
                                        <img className="person__navigation" src={arrowSmall} alt=""/>
                                    </div>
                                </div>
                                <div ref={actMenu} className={actionMenu ? 'person__overlay visible-menu' : 'person__overlay'} >
                                    <ul className="person-action">
                                        <li className="person-action__item">
                                            <NavLink  to="/personal" style={{ textDecoration : 'none' }}>
                                                <span className="person-action__link">
                                                    <img className="person-action__icon" src={customizationIcon} alt="Настройка профиля"/>
                                                    <span className="person-action__label">Настройка профиля</span>
                                                </span>     
                                            </NavLink>
                                        </li>
                                        {/* <li className="person-action__item">
                                            <div className="language">
                                                <img className="language__icon" src={langIcon} width="19" height="20" alt="Выбраный язык"/>
                                                <span className="language__select">Русский</span>
                                            </div>
                                        </li> */}
                                        <li className="person-action__item">
                                            <a className="person-action__link" onClick={logoutHandler}>
                                                <img className="person-action__icon" src={exitIcon} alt="Выход из профиля"/>
                                                <span className="person-action__label">Выход</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}