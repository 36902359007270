import React from 'react'
import parse from 'html-react-parser'

export const SingleNews = ({ setShowSingle, title, short, content }) => {
    return(
        <div className="widget-content">
            <span style={{ cursor : 'pointer' }} onClick={() => setShowSingle(false)}>{`< Назад`}</span>
            <h3>{title}</h3>
            {parse(short)}         
            {parse(content)}         
        </div>   
    )
}