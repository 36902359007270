import { useState, useCallback, useEffect } from 'react'

const storageName = 'userData'

export const useAuth = () => {

    const [ token, setToken ] = useState(null)
    const [ userName, setUserName ] = useState(null)
    const [ userId, setUserId ] = useState(null)
    const [ referal, setReferal ] = useState(null)
    const [ role, setRole ] = useState(null)
    const [ emailVerified, setEemailVerified ] = useState(false)

    const login = useCallback( ( accessToken, uName, id, referal, emailVerified ) => {
        
        setToken(accessToken)
        setUserName(uName)
        setUserId(id)
        setRole(role)
        setEemailVerified(emailVerified)
        setReferal(referal)

        localStorage.setItem(storageName, JSON.stringify({
            token: accessToken, userId: id, userName: uName, referal : referal
        }))

    }, [])

    const logout = useCallback( () => {
        setToken(null)
        setUserName(null)
        setRole(null)
        setUserId(null)
        setReferal(null)
        localStorage.removeItem(storageName)
    }, [])

    useEffect( () => {
        const data = JSON.parse(localStorage.getItem(storageName))

        if(data && data.token) {
            login(data.token, data.userName, data.userId, data.referal)
        }
    }, [login])

    return { login, logout, token, userName, userId, referal, emailVerified  }

}