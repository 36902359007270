import React from 'react'

export const MultipleOrderDescription = () => {
    return(
        <div className="widget-content">
            <p>
                Для создание множественного заказа разместите каждый заказ с новой строки в формате «id услуги | ссылка | количество» и нажмите кнопку "Проверить и рассчитать стоимость заказа". 
            </p>
            <p style={{ paddingTop : '30px' }}>
                <strong>
                Важно!<br/> 
                При нажатии кнопки «Разместить заказ» не закрывайте окно браузера. 
                Дождитесь выполнения операции. Время размещения множественного заказа зависит от количества выбранных услуг и может достигать нескольких минут.
                </strong>
            </p>
        </div>
        
    )
}