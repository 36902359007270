import React, { useState, useContext, useEffect } from "react"
import { Footer } from "../components/Footer"

import person from '../assets/images/svg/person.svg'
import save from '../assets/images/svg/disket.svg'
import { AuthContext } from "../context/AuthContext"
import { HttpContext } from "../context/HttpContext"
import { useHttp } from "../hooks/http.hook"
import { useToasts } from "react-toast-notifications"


export const PersonalPage = () => {

    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)
    const { request, loading } = useHttp()

    const { addToast } = useToasts()

    const [ personal, setPersonal ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        timezone: '',
        password: '',
        website: '',
        phone: '',
        skype: '',
        whatsApp: '',
        address: '',
        apiKey: '',
        newPassword : '',
        newPassword_r : ''
    })

    const getUserData = async () => {
        if(auth.userId !== null){
            try {
                const response = request( 
                    host.url+'/userdata', 
                    'POST', 
                    { client_id : auth.userId },
                    { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
                response.then(data => {
                    setPersonal({
                        ...personal,
                        firstName: data.data.name,
                        lastName: data.data.last_name ? data.data.last_name  : '',
                        email: data.data.email,
                        timezone: data.data.timezone ? data.data.timezone  : '',
                        website: data.data.website ? data.data.website  : '',
                        phone: data.data.phone ? data.data.phone  : '',
                        skype: data.data.skype ? data.data.skype  : '',
                        whatsApp: data.data.whatsApp ? data.data.whatsApp  : '',
                        address: data.data.address ? data.data.address  : '',
                        apiKey: data.data.apiKey ? data.data.apiKey  : '',
                    })
                    
                    // appData.setData({...appData.app, balance : parseFloat(data.data)})
                    // setBalance(data.data)
                })
              } catch(e) {
                console.log(e);
              }
        }
    }

    useEffect(() => {
        getUserData()
    }, [])

    const saveChanges = async (e) => {
        e.preventDefault()
        console.log( personal.newPassword, personal.newPassword_r  )
        if( personal.newPassword !== personal.newPassword_r ) {
            addToast('Пароли не совпадают.', { appearance : 'warning', autoDismiss : true })
            return
        }
        try{
            const response = request(
                host.url+'/userdata',
                'POST',
                { action : 'update', client_id : auth.userId, ...personal },
                { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                addToast(data.message, { appearance : 'success', autoDismiss : true })
            })
        } catch(e) {
            console.log(e)
        }
    }

    const changeHandler = (e) => {
        setPersonal({...personal, [e.target.name] : e.target.value})
    }


    return(
        <section className="content" style={{ filter : loading ? 'blur(2px)' : '' }}>
            <div className="inner">
                <div className="inner__label">
                    <img src={person} alt="данные" />
                    <span>Персональные данные</span>
                </div>
                <div className="inner__row">
                    <div className="inner__col-5 widget">
                        <div className="widget-head">
                            <span className="widget-head__title">Основная информация</span>
                        </div>
                        <div className="widget-content">
                            <form>
                                <div className="form-line">
                                    <div className="wrapper-input">
                                        <label htmlFor="user_name">Имя</label>
                                        <input 
                                            disabled
                                            type="text" 
                                            name="firstName" 
                                            value="Иван" 
                                            value={personal.firstName}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                    <div className="wrapper-input">
                                        <label htmlFor="user_last_name">Фамилия</label>
                                        <input 
                                            type="text" 
                                            name="lastName" 
                                            value={personal.lastName}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-line">
                                    <div className="wrapper-input">
                                        <label htmlFor="user_email">Email</label>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            value={personal.email} 
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                    <div className="wrapper-select">
                                        <label className="select__label" htmlFor="user_time_zone">Часовой пояс</label>
                                        <div className="select">
                                            <select className="select__field" name="user_time_zone" id="user_time_zone">
                                                <option value="( UTC +3:00 )  Europe/Moscow">( UTC +3:00 ) Europe/Moscow
                                                </option>
                                                <option value="( UTC +13:00 )  Europe/America">( UTC +13:00 )
                                                    Europe/America</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-line">
                                    <div className="wrapper-input">
                                        <label htmlFor="user_password">Пароль</label>
                                        <input 
                                            type="password" 
                                            name="newPassword" 
                                            value={personal.newPassword}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                    <div className="wrapper-input">
                                        <label htmlFor="user_password_repeat">Повторите пароль</label>
                                        <input 
                                            onChange={(e) => changeHandler(e)}
                                            type="password" 
                                            name="newPassword_r" 
                                            value={personal.newPassword_r} />
                                    </div>
                                </div>
                                <small style={{ maxWidth: '280px', display: 'inline-block'}}>
                                    Примечание. Если вы не хотите менять пароль,
                                    оставьте эти поля пароля пустыми!
                                </small>
                                <button 
                                    className="form-btn widget-btn" 
                                    style={{ cursor : loading ? 'progress' : 'pointer' }}
                                    disabled={ loading ? true : false }
                                    onClick={(e) => saveChanges(e)}>
                                    <img src={save} alt="Сохранить изменения" />
                                    <span>Сохранить</span>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="inner__col-5 widget">
                        <div className="widget-head">
                            <span className="widget-head__title">Больше информации</span>
                        </div>
                        <div className="widget-content">
                            <form>
                                <div className="form-line">
                                    <div className="wrapper-input">
                                        <label htmlFor="user_site_url">Website</label>
                                        <input 
                                            type="text" 
                                            name="website" 
                                            value={personal.website}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                    <div className="wrapper-input">
                                        <label htmlFor="user_phone">Телефон</label>
                                        <input 
                                            type="text" 
                                            name="phone"  
                                            value={personal.phone}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-line">
                                    <div className="wrapper-input">
                                        <label htmlFor="user_skype_id">Skype ID</label>
                                        <input 
                                            type="text" 
                                            name="skype" 
                                            value={personal.skype}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                    <div className="wrapper-input">
                                        <label htmlFor="user_whatsapp">WhatsApp Number</label>
                                        <input 
                                            type="text" 
                                            name="whatsApp"
                                            value={personal.whatsApp}
                                            onChange={(e) => changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="wrapper-input">
                                    <label htmlFor="user_address">Адрес</label>
                                    <input 
                                        type="text" 
                                        name="address" 
                                        value={personal.address}
                                        onChange={(e) => changeHandler(e)} />
                                </div>
                                <small style={{ maxWidth: '280px', display: 'inline-block', marginTop: '14px'}}>
                                    Примечание. Если вы не хотите добавлять дополнительную
                                    информацию, оставьте эти поля информации пустыми!
                                </small>
                                <button 
                                    className="form-btn widget-btn" 
                                    style={{ cursor : loading ? 'progress' : 'pointer' }}
                                    disabled={ loading ? true : false }
                                    onClick={(e) => saveChanges(e)} >
                                    <img src={save} alt="Сохранить изменения" />
                                    <span>Сохранить</span>
                                </button>
                            </form>
                        </div>
                    </div>

                    {/* <div className="inner__col-5 widget">
                        <div className="widget-head">
                            <span className="widget-head__title">Your API key</span>
                        </div>
                        <div className="widget-content">
                            <form>
                                <div className="wrapper-input">
                                    <label htmlFor="user_api_key">Key</label>
                                    <input 
                                        className="input-key" 
                                        type="text" name="apiKey"
                                        value={personal.apiKey} />
                                </div>
                                <button className="form-btn widget-btn" style={{marginTop: '30px'}}>
                                    <span>Сгенерировать новый</span>
                                </button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer/>
        </section>
    )
}