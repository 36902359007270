import React from 'react'
import { useData } from '../context/DataContext'
import fix1 from '../assets/images/svg/fix-icon1.svg'
import fix2 from '../assets/images/svg/fix-icon2.svg'
import { NavLink } from 'react-router-dom'

export const MobileNav = () => {

    const appData = useData()

    return(
        <div className="fixed-mobile-nav">
                <NavLink to="/orders" className="fixed-mobile-nav__item">
                    <span className="fixed-mobile-nav__item">
                        <div className="fixed-mobile-nav__element-top">
                            <img src={fix1} alt="заказы"/>
                        </div>
                        <div className="fixed-mobile-nav__element-bottom">Заказы</div>
                    </span>
                    
                </NavLink>
                <NavLink to="/order" className="fixed-mobile-nav__item fixed-mobile-nav__item--center">
                    <span className="fixed-mobile-nav__item fixed-mobile-nav__item--center">
                        <div className="fixed-mobile-nav__element-top">
                            <img src={fix2} alt="Новый заказ"/>
                        </div>
                        <div className="fixed-mobile-nav__element-bottom">Новый заказ</div>
                    </span>                    
                </NavLink>
                <NavLink to="/payment" className="fixed-mobile-nav__item fixed-mobile-nav__item--right">
                    <span className="fixed-mobile-nav__item fixed-mobile-nav__item--right">
                        <div className="fixed-mobile-nav__element-top">
                            {appData.app.balance} ₽
                        </div>
                        <div className="fixed-mobile-nav__element-bottom">Баланс</div>
                    </span>
                </NavLink>
        </div>
    )
}