import React, { useState, useContext, useEffect} from 'react'


import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'
import { HttpContext } from '../context/HttpContext'


import { Footer } from '../components/Footer'
import { Payments } from '../components/transactions/Payments';
import { Spendings } from '../components/transactions/Spendings';
import trans from '../assets/svg/trans.svg'

export const TransactionsPage = ()=> {
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const [balance, setBalance] = useState()
    

    const { loading, error, request } = useHttp()

    const [payments, setPayments] = useState([])
    const [spendings, setSpendings] = useState([])

    const [tab1, setTab1] = useState({
        mainClass : 'list-select',
        addClass : 'active-list',
        list : 'show-list'
    })
    const [tab2, setTab2] = useState({
        mainClass : 'list-select',
        addClass : '',
        list : 'hide-list'
    })

    const getHistory = async () => {
        try {
            const response = request( host.url+'/history', 'POST', { client_id : auth.userId }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                setPayments(data.data.payments)
                setSpendings(data.data.spendings)
            })
          } catch(e) { console.log(e) }
    }
    
    const [tabs, setTabs] = useState({ tab1 : true, tab2 : false })

    useEffect(() => {
        async function fetchData() {
            await getHistory();
        }
        fetchData();
    }, [])

    const paymentsHandler = (e) => {
        setTab1({ mainClass : 'list-select', addClass : 'active-list', list : 'show-list'})
        setTab2({ mainClass : 'list-select', addClass : '', list : 'hide-list'})
        setTabs({...tabs, tab1 : true, tab2 : false})
    }

    const spendingsHandler = (e) => {
        setTab1({ mainClass : 'list-select', addClass : '', list : 'hide-list'})
        setTab2({ mainClass : 'list-select', addClass : 'active-list', list : 'show-list'})
        setTabs({...tabs, tab1 : false, tab2 : true})
    }

 
    return(
        <section className="content" style={{ filter : loading ? 'blur(2px)' : '' }}>

            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <img className="widget-head__icon" src={trans} alt="транзакций" width="20" height="20" />
                        <span className="widget-head__title">Журнал транзакций</span>
                    </div>
                    <div className="widget-content">
                        <div className="sort-table">
                            <div className="sort-table__row">
                                <ul className="sort-table-list">
                                    <li className={ tabs.tab1 && 'is-active' }><a onClick={paymentsHandler} style={{ cursor : 'pointer' }}>Пополнение</a></li>
                                    <li className={ tabs.tab2 && 'is-active' }><a onClick={spendingsHandler} style={{ cursor : 'pointer' }}>Списание</a></li>
                                </ul>
                            </div>
                        </div>
                        {!loading &&
                        <div className="wrapper-table">
                            <table className="even transactions-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Сумма</th>
                                        <th>Статус</th>
                                        <th>Дата</th>
                                        <th>Примечание</th>
                                    </tr>
                                </thead>
                                {tabs.tab1 ?
                                <tbody>
                                    {payments.slice(0).reverse().map((item, id) => <Payments {...item} total={payments.length} itemId={id} styles={tab1}/>)}
                                </tbody> :
                                <tbody>
                                    {spendings.slice(0).reverse().map((item, id) => <Spendings {...item} total={spendings.length} itemId={id} styles={tab2}/> )}
                                </tbody>}
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer/>
        </section>
    )
}