import React, { useRef } from "react"
import { Footer } from '../components/Footer'
import question from '../assets/svg/question.svg'
import loopW from '../assets/svg/loop-w.svg'
import faqIcon from '../assets/svg/faq-icon.svg'

export const FaqPage = () => {

    const faqRef = useRef([])

    const hiddenContentHAndler = (id) => {
        // faqRef.current.map(item => [
        //     item.classList.remove('is-show')
        // ])
        if(faqRef.current[id].classList.contains('is-show')){
            faqRef.current[id].classList.remove('is-show')
        } else {
            faqRef.current[id].classList.add('is-show')
        }
        
    }

    return(
        <section className="content">
            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <div className="widget-head__wrapper">
                            <img className="widget-head__icon" src={question} alt="Вопросы" width="20" height="20"/>
                            <span className="widget-head__title">FAQ</span>
                        </div>
                        <form className="search-form">
                            {/* <div className="field-wrap">
                                <input type="text" placeholder="Поиск по FAQ"/>
                                <button className="search-form__btn btn">
                                    <img src={loopW} alt="Поиск" width="16" height="16px"/>
                                </button>
                            </div> */}
                        </form>
                    </div>
                    <div className="widget-content">
                       <div className="faq-row">
                        <div className="faq-col">
                            <div className="faq-box" onClick={() => hiddenContentHAndler(0)} ref={el => faqRef.current[0] = el}>
                                <button className="faq-box__head">
                                    <img className="faq-box__icon" src={faqIcon} alt="Вопрос"/>
                                    <span className="faq-box__label"> Как заказать накрутку?</span>
                                </button>
                                <div className="faq-box__content">
                                    <div className="faq-box__inner">
                                        <p>
                                        Вам нужно пополнить баланс. После пополнения баланса, зайдите в раздел заказы (новый заказ), выберите нужную услугу, укажите ссылку на накручиваемое видео или пост, укажите желаемое количество, подтвердите заказ и нажмите "разместить заказ".
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-box" onClick={() => hiddenContentHAndler(1)} ref={el => faqRef.current[1] = el}>
                                <button className="faq-box__head">
                                    <img className="faq-box__icon" src={faqIcon} alt="Вопрос"/>
                                    <span className="faq-box__label"> Как посмотреть статус выполнения заказа?</span>
                                </button>
                                <div className="faq-box__content ">
                                    <div className="faq-box__inner">
                                        <p>
                                            Вам нужно войти в раздел заказы (список заказов), далее Вы увидите все свои оформленные заказы и сможете посмотреть их статус и всю информацию по процессу выполнения.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="faq-box" onClick={() => hiddenContentHAndler(2)} ref={el => faqRef.current[2] = el}>
                                <button className="faq-box__head">
                                    <img className="faq-box__icon" src={faqIcon} alt="Вопрос"/>
                                    <span className="faq-box__label">Насколько безопасна накрутка?</span>
                                </button>
                                <div className="faq-box__content">
                                    <div className="faq-box__inner">
                                        <p>
                                            Наш сервис полностью безопасен, Вашим накручиваемым социальным сетям не грозят ни какие санкции после накрутки.
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="faq-col">
                            <div className="faq-box" onClick={() => hiddenContentHAndler(3)} ref={el => faqRef.current[3] = el}>
                                <button className="faq-box__head">
                                    <img className="faq-box__icon" src={faqIcon} alt="Вопрос"/>
                                    <span className="faq-box__label">Как правильно выполнить накрутку?</span>
                                </button>
                                <div className="faq-box__content">
                                    <div className="faq-box__inner">
                                        <p>
                                            Можно выполнять только один тип накрутки на одно видео, пост, аккаунт и т.д. Если вы накручиваете просмотры на какое либо видео или т.п., то до завершения выполнения услуги нельзя повторно оформлять накрутку на это видео или т.п. Также если вы накручиваете подписчиков, Вам нельзя оформлять накрутку подписчиков на данный аккаунт, пока не завершится выполнение прошлой накрутки.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-box" onClick={() => hiddenContentHAndler(4)} ref={el => faqRef.current[4] = el}>
                                <button className="faq-box__head">
                                    <img className="faq-box__icon" src={faqIcon} alt="Вопрос"/>
                                    <span className="faq-box__label">Какое время выполнения накрутки?</span>
                                </button>
                                <div className="faq-box__content">
                                    <div className="faq-box__inner">
                                        <p>
                                            В описании каждой услуги есть информация по времени выполнения данной услуги.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </section>
    )
}