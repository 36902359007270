import React, { useEffect, useState } from 'react'

export const OrderPrice = ({ orderState, appliedCouponValue }) => {

    const [ displayDiscount, setDisplyaDiscount ] = useState( false )

    const [ oldPrice, setOldPrice ] = useState(20)

    useEffect(() => {
        
        appliedCouponValue !== null ? setDisplyaDiscount( true ) : setDisplyaDiscount( false )

        orderState.total === 0 && setDisplyaDiscount( false )

        setOldPrice( ( orderState.quantity * parseInt(orderState.rate) ) / 1000)

    }, [ appliedCouponValue, orderState ])

    return(
        <div className="payment">
            <span className="payment__name">Сумма к оплате:</span>
            <span className="payment__quantity">
                {displayDiscount ?
                <><span style={{ textDecoration : 'line-through', fontSize : '25px', color : 'grey', paddingRight : '10px'}}>{oldPrice.toFixed(2)}</span>{orderState.total.toFixed(2)}</>
                :
                orderState.total.toFixed(2)}
            </span>
            <span className="payment__currency">₽</span>
        </div>
    )
}