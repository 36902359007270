import { useState } from "react"

export const OrderItem = ({id, link, price, start_count, quantity, service, status, remains, comment, created_at }) => {

    let statusRus
    let classCircle
    if(status === 'Completed') {
        statusRus = 'Завершён'
        classCircle = 'status status--completed'
    }
    if(status === 'Pending') {
        statusRus = 'Ожидание'
        classCircle = 'status status--promice'
    }
    if(status === 'In progress') {
        statusRus = 'В работе'
        classCircle = 'status status--inwork'
    }
    if(status === 'Canceled') {
        statusRus = 'Отменен'
        classCircle = 'status status--canceled'
    }
    if(status === 'Processing') {
        statusRus = 'В очереди'
        classCircle = 'status status--queue'
    }
    if(status === 'Partial'){
        statusRus = 'Выполнен частично'
        classCircle = 'status status--canceled'
    }

    const [ showInfo, setShowInfo ] = useState(false) 

    return(
        <tr>
            <td>{id}</td>
            <td>{created_at}</td>
            <td>
                <a style={{ wordWrap : 'break-word' }} href={link} target='_blank'>{link.slice(0,-(link.length - 120))}</a>
            </td>
            <td>{price} руб.</td>
            <td>{start_count}</td>
            <td>{quantity}</td>
            <td>{service}</td>
            <td>
                <span className={classCircle}></span>
                <span style={{ position : 'relative' }}>{statusRus} 
                {status === 'Canceled' && comment !== null &&
                <button className="widget-btn" onClick={() => setShowInfo( showInfo ? false : true )} style={{ 
                    width : 'fit-content', 
                    padding : '0 5px', 
                    fontSize : '10px',
                    height : 'auto',
                    lineHeight : '20px',
                    // marginTop : '3px'
                    margin : '3px auto 0',
                    
                    }}>инфо</button>}
                {showInfo &&
                <div style={{
                    position : 'absolute',
                    background : '#fff',
                    padding : '10px',
                    width : '100px',
                    boxShadow: '0px 0px 10px rgb(0 28 61 / 5%)',
                    borderRadius : '10px',
                    border : '1px solid lightgray',
                    left : '50%',
                    transform : 'translate(-50%, 5px)',
                    zIndex : 100000
                }}>
                    {comment}
                </div>
                }    
                </span>
                {/* {status === 'Canceled' &&
                <button>Причина</button>
                } */}
            </td>
            <td>{remains}</td>
        </tr>
    )
}