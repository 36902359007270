import React, { useState, useEffect } from 'react'

export const MultiOrderItem = ({ id, url, qty, services, auth, mOrderItems, setMOrderItems, setMultiorderErrors }) => {

    const [errorMessage, setErrorMessage] = useState('')

    const [itemInput, setItemInput] = useState({
        id : id.replace(/\s/g, ''),
        url : url.replace(/\s/g, ''),
        qty : qty.replace(/\s/g, '')
    })

    const changeHandler = (e) => {
        setItemInput({...itemInput, [e.target.name] : e.target.value})
    }

    const checkErrors = () => {

        let err = errorMessage

        if(!parseInt(itemInput.id)){
            const prevMessage = err
            err = prevMessage + ' ' + 'Id заказа должен быть числом.'
        }

        if(!parseInt(itemInput.qty)){
            const prevMessage = err
            err = prevMessage + ' ' + 'Неверно указано количество.'
        }

        /**
         * Проверяем правильность услуги. 
         * Существует ли ID номер и находится ли указанное
         * количество в допустимом диапазоне
         */
       
        let servTm = {} /** В эту переменную сохраняем найденную по ID услугу  */
        services.map((item, i) => {
            if( parseInt(id) === parseInt(item.service) ){
                
                servTm = item
            }       
        })

        /** Проверяем валидность ID услуги */
        if( Object.keys(servTm).length === 0 ) {
            setErrorMessage('Неверный ID услуги')
            return false
        }

        /** Проверяем допустимый диапазон */
        if( parseInt(qty) >= parseInt(servTm.min) && parseInt(qty) <= parseInt(servTm.max) ) {
            /** Если соответствует условиям, то записываем в массив */

            const sumTotal = ( parseInt(qty) * parseInt(servTm.rate) ) / 1000
            let tmp = []
            console.log(mOrderItems)
            tmp = mOrderItems
            let tmp1 = tmp.push({
                api_id : servTm.service,
                order_title : servTm.name,
                quantity : qty,
                link : url,
                sumTotal : sumTotal,
                client_id : auth.userId,
                // service : orderState.catName,
                service : servTm.name,
                serviceId : servTm.service
            })
            // console.log(tmp)
            setMOrderItems(tmp1)
            
        } else {
            setErrorMessage(`Количество должно быть в диапозоне от ${servTm.min} до ${servTm.max}`)
            return
        }
        
        setErrorMessage(err)
        
        
    }

    useEffect(() => {
        checkErrors()
    }, [id])

    useEffect(() => {
        if(errorMessage.length > 0) {
            setMultiorderErrors('Внимание! В заказе имеются неправильно заполненные услуги. Вы можете разместить заказ без них, либо отменить заказ и начать сначала.')
        }
    }, [errorMessage])

    
    return(
        <div className={errorMessage.length ? 'danger' : ''}>
            <span className="item-error">{errorMessage}</span>
            <div style={{ display : 'flex', marginBottom : '5px' }} key={id}>
                <input style={{ width : '15%' }} name="id" value={itemInput.id} onChange={(e) => changeHandler(e)} disabled />
                <input style={{ width : '65%' }} name="url" className="multi-url" value={itemInput.url} onChange={(e) => changeHandler(e)} disabled />
                <input style={{ width : '20%' }} name="qty" value={itemInput.qty} onChange={(e) => changeHandler(e)} disabled /> 
            </div>
        </div>
    )
}