import { useState, useCallback, useContext } from 'react'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { AuthContext } from '../context/AuthContext'

export const useHttp = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)

    const auth = useContext(AuthContext)
    const history = useHistory()

    const { addToast } = useToasts()

    const request = useCallback( async (url, method = 'GET', body = 'null', headers = {}) => {
        setLoading(true)
        try {
            if(body) {
                body = JSON.stringify(body)
                headers['Content-Type'] = 'application/json'
            }

            const response = await fetch( url, { method, body, headers } )
            
            if(response.status === 500){
                // console.log(response)
                auth.logout()
                history.push('/')
                addToast('Ваша сессия истекла. Пожалуйста авторизируйтесь.', { appearance : 'error', autoDismiss : true, autoDismissTimeout : 5000 })
                return false
            }

            const data = await response.json()

            // if(response.ok) {
            //     console.log(data.message)
            // }

            setLoading(false)

            return data

        } catch(e) {
            setLoading(false)
            setError(e.message)
            throw e
        }
    })

    const clearError = useCallback( () => setError(null), [] )

    return { loading, request, error, clearError }
}