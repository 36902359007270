export const Spendings = ({itemId, value, order_id, created_at, total}) => {

    const number = total - itemId 

    return(
        <tr>
            <td>{number}</td>
            <td>{value} ₽</td>
            <td>Оплата заказа № {order_id}</td>
            <td>{created_at}</td>
            <td>Оплата заказа № {order_id}</td>
        </tr>
    )
}