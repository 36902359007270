import React  from "react"

export const OrdersTableHead = () => {
    return(
        <thead>
            <tr>
                <th>ID</th>
                <th>Дата</th>
                <th>Ссылка</th>
                <th>Сумма</th>
                <th>Стартовое число</th>
                <th>Количество</th>
                <th>Услуга</th>
                <th>Статус</th>
                <th>Осталось</th>
            </tr>
        </thead>
    )
}