import React from 'react'
import { Footer } from '../components/Footer'
import wallet from '../assets/svg/wallet.svg'
export const PayoutPage = () => {
    return(
        <section className="content" >
            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <img className="widget-head__icon" src={wallet} alt="Вопросы" width="20" height="20" />
                        <span className="widget-head__title">Вывод средств</span>
                    </div>
                    <div className="widget-content">
                        <h3 style={{ marginTop : '0' }}>Вывод заработанных средств</h3>
                        <p>Для вывода доступны только средства, заработанные с рефералов. Минимальная сумма для вывода - 100 руб. Для создания заявки на вывод, необходимо чтобы текущий баланс был больше или равен доступной суммы для вывода</p>
                        <div style={{ display : 'flex', flexDirection : 'column' }}>
                            <span>Доступно для вывода: <strong>0 руб.</strong></span>
                            <p style={{ fontWeight : 'bold', paddingTop : '20px' }}>Внимание: Обновление доступной суммы для вывода происходит раз в сутки</p>
                            {/* <button className='btn btn-payment' style={{ width : 'fit-content', marginTop : '20px' }}>Заявка на вывод</button> */}
                        </div>
                        
                    </div>    
                </div>
            </div>
            <Footer/>
        </section>
    )
}