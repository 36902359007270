import React, { useState, useEffect, useContext, useRef } from "react"
import { useToasts } from "react-toast-notifications"
import { AuthContext } from "../context/AuthContext"
import { HttpContext } from "../context/HttpContext"
import { useHttp } from "../hooks/http.hook"
import { Footer } from '../components/Footer'
import prices from '../assets/svg/prices.svg'
import loop from '../assets/svg/loop-g.svg'

export const PricesPage = () => {

    const pricesBlocks = useRef([])

    const { addToast } = useToasts()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const { loading, error, request } = useHttp()

    const [link, setLink] = useState('')
    
    const [services, setServices] = useState([])

    const [categories, setCategories] = useState([])
    const [availableOrderType, setAvailableOrderType] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)

    const [ search, setSearch ] = useState('')

    const [ found, setFound ] = useState([])

    const [orderState, setOrderState] = useState({
        id : '',
        min : '',
        max : '',
        rate : '',
        catName : '',
        servName : '',
        quantity : '',
        total : 0
    })

    const getServices = async () => {
        setOrdersLoading(true)
        try {
            const response = request( host.url+'/services', 'POST', { action : 'getAvailableServices' }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                setServices(data.data)
                setOrdersLoading(false)
                
            })
          } catch(e) { 
              console.log(e)
              setOrdersLoading(false)
          }
    }

    useEffect(() => {
        getServices()
    }, [])

    useEffect(() => {
        let cat = []
        services.map((item, id) => {
            cat.push(item.category)
        });
        const uniqueCats = new Set(cat)
        const uniqueCatsArr = [...uniqueCats]

        setCategories(uniqueCatsArr)

        const catOrd = uniqueCatsArr[0]
        let availableCats = []
        services.map(item => {
            item.category === catOrd && availableCats.push(item)
        })
       
        setAvailableOrderType(availableCats)
        availableOrderType.length > 0 &&
            setOrderState({...orderState, 
                id : availableOrderType[0].service, 
                min : availableOrderType[0].min, 
                max : availableOrderType[0].max, 
                rate : availableOrderType[0].rate,
                servName : availableOrderType[0].name,
                catName : availableOrderType[0].category })
        
    }, [ services ])

    const [ foundCategories, setFoundCategories ] = useState([])

    useEffect(() => {
        // let tmpCat = []
        // uniqueCatsArr.map(item => {
           
        //     let tm = []
        //     services.map(itm => {
        //         if(itm.category === item) {
        //             tm.push(item)
        //         }
        //         // itm.category === item && 

        //     })

        //     if(tm.length > 0){
        //         tmpCat.push(item)
        //     }
            
        // })
        let cat = []
        found.map((item, id) => {
            cat.push(item.category)
        });
        const uniqueCats = new Set(cat)
        const uniqueCatsArr = [...uniqueCats]

        setFoundCategories(uniqueCatsArr)
    }, [ found ])


    const hiddenContentHandler = (i) => {
        

        if(pricesBlocks.current[i].classList.contains('serv-hidden-show')){
            pricesBlocks.current[i].classList.remove('serv-hidden-show')
        } else{
            pricesBlocks.current[i].classList.add('serv-hidden-show')
        }
        
    }

    const [ detailsModal, setDetailsModal ] = useState({
        state : false,
        title : '',
        content : '',
        duration : ''
    })
    const showDetails = ( e, id ) => {
        e.preventDefault()

        let det = {}

        services.map(item => {
            if( item.service === id ) {

                det = { title : item.name, content : item.description, duration : item.duration }

            }
        })

        setDetailsModal({...detailsModal, state : true, ...det})

    }

    const closeDetailsModal = () => {
        setDetailsModal({...detailsModal, state : false})
    }

   const [ notFound, setNotFound ] = useState( false )

    const searchRequest = ( e ) => {
       
        setSearch(e.target.value)

        if(e.target.value === '') {
            
            setFound([])

            return

        }

        let tmp = []
            
        services.map(item => {

            if( item.name.toLowerCase().includes( e.target.value.toLowerCase() ) || item.category.toLowerCase().includes( e.target.value.toLowerCase() ) ){

                tmp.push( item )

            }

        })

        console.log(tmp.length)

        if(tmp.length === 0) {
            setNotFound(true)
        } else {
            setNotFound(false)
        }

        setFound( tmp )

        

    }


    return(
        <section className="content">
            <div className="details-pop-up" style={{ display : detailsModal.state ? 'block' : 'none' }}>
                <div className="details-pop-up__wrapper">
                    <div className="details-pop-up__content">
                        <h2>{detailsModal.title}</h2>
                        <p>{detailsModal.content}</p>
                        <span>Время выполнения заказа: {detailsModal.duration}</span>
                        <button 
                            onClick={closeDetailsModal}
                            className="btn-small"
                            >Закрыть</button>
                    </div>
                </div>
            </div>
            <div className="inner">
                <div className="prices__row">
                    <div className="prices__label">
                        <img src={prices} alt="Цены"/>
                        <span>Цены</span>
                    </div>
                    <form className="search-form">
                        <div className="field-wrap">
                            <input 
                                type="text" 
                                placeholder="Поиск по услугам" 
                                value={search}
                                onChange={( e ) => searchRequest( e )} 
                                />
                            <button className="search-form__btn btn">
                                <img src={loop} alt="Поиск по услугам" width="16" height="16px"/>
                            </button>
                        </div>
                    </form>
                </div>
                {loading ? <h3>Загрузка...</h3> :
                found.length === 0 && !notFound ? 
                
                categories.map((item, id) => 
                    <div className="box-category">
                        <button className="box-category__head " onClick={(e) => hiddenContentHandler(id)}> 
                            {/* <img className="box-category__icon" src="svg/tik.svg" alt="Тик-ток"/> */}
                            <span className="box-category__label">{item}</span>
                        </button>
                        <div className="box-category__content hidden-content" ref={el => pricesBlocks.current[id] = el}>
                            <div className="box-category__inner">
                                <div className="wrapper-table wrapper-table-prices">
                                    <table className="even">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Имя</th>
                                                <th>Цена За 1000 Шт(Руб)</th>
                                                <th>Мин. / Макс. Заказ</th>
                                                <th>Описание</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {services.map(item1 => 
                                                item1.category === item &&
                                                <tr>
                                                    <td>{item1.service}</td>
                                                    <td>{item1.name}</td>
                                                    <td>{item1.rate}р</td>
                                                    <td>{item1.min} / {item1.max}</td>
                                                    <td><a className="btn-small" href="" onClick={( e ) => showDetails( e, item1.service )}>Детали</a></td>
                                                </tr>
                                            )}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>)
                :
                !notFound ? 
                foundCategories.map((item, id) => 
                    <div className="box-category">
                        <button className="box-category__head " onClick={(e) => hiddenContentHandler(id)}> 
                            {/* <img className="box-category__icon" src="svg/tik.svg" alt="Тик-ток"/> */}
                            <span className="box-category__label">{item}</span>
                        </button>
                        <div className="box-category__content hidden-content" ref={el => pricesBlocks.current[id] = el}>
                            <div className="box-category__inner">
                                <div className="wrapper-table wrapper-table-prices">
                                    <table className="even">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Имя</th>
                                                <th>Цена За 1000 Шт(Руб)</th>
                                                <th>Мин. / Макс. Заказ</th>
                                                <th>Описание</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {found.map(item1 => 
                                                item1.category === item &&
                                                <tr>
                                                    <td>{item1.service}</td>
                                                    <td>{item1.name}</td>
                                                    <td>{item1.rate}р</td>
                                                    <td>{item1.min} / {item1.max}</td>
                                                    <td><a className="btn-small" href="" onClick={( e ) => showDetails( e, item1.service )}>Детали</a></td>
                                                </tr>
                                            )}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>)
                :
                <h3>По вашему запросу ничего не найдено...</h3>   
                }
            </div>
            <Footer />
        </section>
    )
}