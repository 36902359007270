import React, { useState, useContext } from 'react'
import { useToasts } from "react-toast-notifications"
import { useHttp } from "../../hooks/http.hook"
import { AuthContext } from "../../context/AuthContext"
import { HttpContext } from "../../context/HttpContext"

export const PromoCode = ({ setAppliedCouponValue }) => {

    const { addToast } = useToasts()

    const { request, loading } = useHttp()

    const auth = useContext(AuthContext)

    const host = useContext(HttpContext)

    const [ coupon, setCoupon ] = useState('')

    const [ discount, setDiscount ] = useState(0)

    const [ isDisabled, setIsDisabled ] = useState( false )
 
    const applyCoupon = async ( e ) => {

        if( isDisabled ) {
            return false
        }

        if(coupon.length === 0){
            addToast('Укажите код купона', { appearance : 'warning', autoDismiss : true })
            return
        }

        try {
            const response = request( host.url+'/coupon', 'POST', 
            { action : 'newTicket', userId : auth.userId, coupon : coupon }, 
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {

                if( ! data.success ) {
                    addToast( data.message , { appearance : 'error', autoDismiss : true })
                    return
                }

                addToast( data.message, { appearance : 'success', autoDismiss : true })

                setAppliedCouponValue( data.data[0].discount )

                setDiscount( data.data[0].discount )

                setIsDisabled( true )
                
            })
        } catch(e) { console.log(e) }
    }

    return(
        <>
        <div className="wrapper-input promocode-wrapper">
            <h3 style={{ paddingTop : '0' }}>Промокод:</h3>
            <div className="input input--numb inp-promo">
                <input 
                    type="text" 
                    name="promocode" 
                    id="promocode"
                    onChange={( e ) => setCoupon( e.target.value )} 
                    value={coupon}
                    disabled={isDisabled}
                    />
            </div>
            <span 
                className="btn btn-payment" 
                style={{ lineHeight : '9px', cursor : isDisabled ? 'not-allowed' : 'pointer' }} 
                onClick={( e ) => applyCoupon( e )}>Применить</span>
        </div>
        {discount !== 0 &&
        <span style={{ paddingTop : '10px', display : 'block', fontSize : '14px', fontWeight : 'bold' }}>Промокод "{coupon}" успешно применен! Вы получаете скидку {discount}%!</span>}
        </>
    )
}