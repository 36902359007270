import React, { useState } from 'react'

import { useAuth } from './hooks/auth.hook'
import { UseRoutes } from './routes'
import { AuthContext } from './context/AuthContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { DataProvider } from './context/DataContext'
import { ToastProvider } from 'react-toast-notifications'
import { Header } from './components/Header'
import { NavPanel } from './components/NavPanel'
import { MobileNav } from './components/MobileNav'
import { YMInitializer } from 'react-yandex-metrika';




function App() {

  const { token, login, logout, userName, userId, emailVerified, referal } = useAuth() 
  const isAuthenticated = !!token
  const routes = UseRoutes(isAuthenticated)

  const [ menus, setMenus ] = useState({ left : false, right : false })

  const menusHandler = ( e ) => {
    if( !e.target.classList.contains('navigation-list__name') ) {
      setMenus({...menus, left : false, right :  false})
    }
  }

  const [ notificationHeight, setNotificationHeight ] = useState(0)

  return (
    <AuthContext.Provider value={{ token, login, logout, userName, userId, emailVerified, referal, isAuthenticated  }}>
      <DataProvider>
        <ToastProvider>
          <Router>
            <YMInitializer accounts={[86565879]} options={{webvisor: true}} version="2" />
            {isAuthenticated ? 
            <>
              <Header menus={menus} setNotificationHeight={setNotificationHeight} notificationHeight={notificationHeight}/>
              <div className="wrapper-page" onClick={ ( e ) => menusHandler( e ) } style={{ marginTop : 70 + notificationHeight + 'px' }}>
                <NavPanel notificationHeight={notificationHeight}/>
                { routes }
                <MobileNav/>
              </div>
            </>
            : 
            routes}
          </Router>
        </ToastProvider>
      </DataProvider>
    </AuthContext.Provider>
    
  )
}

export default App
