import React from 'react'

export const FastOrderDescription = () => {
    return(
        <div className="widget-content" style={{ heigth : 'fit-content' }}>
            <p>
                Для быстрого создания заказа пройдите 5 простых шагов:
            </p>
            <p>
                1 - выберите интересующую социальную сеть
            </p>
            <p>
                2 - выберите категорию услуги
            </p>
            <p>
                3 - выберите услугу
            </p>
            <p>
                4 - вставьте ссылку на профиль /пост / комментарий 
            </p>
            <p>
                5 - укажите желаемое количество
            </p>
        </div>
        
    )
}