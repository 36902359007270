export const Footer = () => {
    return(
        <footer className="footer-content">
               <div className="footer-content__row">
                   <div className="ID"></div>
                   <div className="footer-content__links">
                       <ul className="footer-list">
                           <li><a href="https://www.instantbooster.ru/oferta" target="_blank">Публичная оферта</a></li>
                           <li><a href="https://www.instantbooster.ru/policy" target="_blank">Конфиденциальность</a></li>
                           {/* <li><a href="/">Правила</a></li> */}
                       </ul>
                       <span className="year-work">© 2019 - 2021 </span>
                   </div>
               </div>
           </footer>
    )
}