import React, { useState, useEffect, useRef } from 'react'
import plusW from '../../assets/svg/plus-w.svg'
import windows from '../../assets/svg/windows.svg'

import instagramIco from '../../assets/images/social-icons/icons8-instagram.svg'
import teleramIco from '../../assets/images/social-icons/icons8-telegram-app.svg'
import tiktokIco from '../../assets/images/social-icons/icons8-tiktok.svg'
import vkIco from '../../assets/images/social-icons/icons8-vk-com.svg'
import youtubeIco from '../../assets/images/social-icons/icons8-youtube.svg'
import { PromoCode } from '../promo-code/PromoCode'
import { OrderPrice } from '../order-price/OrderPrice'


export const FastOrder = ({ categoryHandler, categories, typeHandler, availableOrderType, linkHandler, link, available, quantityHandler, orderState, createOrderHandler, btnLoading, orderCreated, appliedCouponValue, setAppliedCouponValue}) => {

    const [ socialNet, setSocialNet ] = useState( null )

    const [ categoriesNew, setCategoriesNew ] = useState([])

    const [ servicesNew, setServicesNew ] = useState([])

    const [ serviceId, setServiceId ] = useState( null )

    const categoriesRef = useRef([])

    const servicesListRef = useRef( null )

    const servicesRef = useRef([])

    const finalForm = useRef( null )

    const socialNetHandler = ( net ) => {
        
        setSocialNet( net )

        setServicesNew([])

        setServiceId( null )
     
        const nc = []

        categories.forEach(item => {
            
            if(net === 'instagram'){
                if(item.toLowerCase().includes( 'instagram' ) ) {
                    nc.push(item)
                   
                }
                if(item.toLowerCase().includes( 'инстаграм' ) ){
                    nc.push(item)
                }
                
            }

            if(net === 'tiktok'){
                if(item.toLowerCase().includes( 'tiktok' ) ) {
                    nc.push(item)
                   
                }
            }

            if(net === 'vk'){
                if(item.toLowerCase().includes( 'vk' ) ) {
                    nc.push(item)
                   
                }
            }

            if(net === 'youtube'){
                if(item.toLowerCase().includes( 'youtube' ) ) {
                    nc.push(item)
                   
                }
            }

            if(net === 'telegram'){
                if(item.toLowerCase().includes( 'telegram' ) ) {
                    nc.push(item)
                   
                }
            }
        })

        setCategoriesNew( nc )

        setTimeout(() => {
            categoriesRef.current.map(item => {
                item !== null && item.classList.remove('active-social')
            })
        }, 100)

    }

    const serviceHandler = ( id, serv ) => {
        categoryHandler( serv )
        setServiceId( null )
        setServicesNew( availableOrderType )
        
        categoriesRef.current.map(item => {
            item !== null && item.classList.remove('active-social')
        })

        servicesRef.current.map(item => {
            item !== null && item.classList.remove('active-social')
        })

        categoriesRef.current[id].classList.add('active-social')

        setTimeout(() => {
            servicesListRef.current.scrollIntoView({ behavior: 'smooth',  block: 'start' })
        }, 100)
       
     
    }

    const finalServiceHandler = ( id, serv  ) => {

        if(servicesRef.current[id].classList.contains('active-social')){
            setTimeout(() => {
                finalForm.current.scrollIntoView({ behavior: 'smooth',  block: 'start' })
            }, 100)
            return
        }

        servicesRef.current.forEach(item => {
            item !== null && item.classList.remove('active-social')
        })

        servicesRef.current[id].classList.add('active-social')
        setServiceId(serv.service)

       

        typeHandler(serv.service)
        
    }

    useEffect(() => {
        setSocialNet( null )

        setCategoriesNew([])
    
        setServicesNew([])
    
        setServiceId( null )
        console.log('order has been created...')
    }, [ orderCreated ] )

    const completeOrder = ( e ) => {
        e.preventDefault()
        setTimeout(() => {
            finalForm.current.scrollIntoView({ behavior: 'smooth',  block: 'start' })
        }, 100)
    }

    return(
        <div className="inner__col-5 widget accent">
                        <div className="widget-head">
                            <img className="widget-head__icon" src={plusW} alt="Добавить" width="20" height="20"/>
                            <span className="widget-head__title">Быстрый заказ</span>
                        </div>
                        <div className="widget-content">
                            <form>
                                <h3 style={{ paddingTop : '0', marginTop : 0 }}>1. Выберите социальную сеть:</h3>
                                <div className='social-select'>
                                    <div className={`social-item ${socialNet === 'instagram' ? 'active-social' : ''}`} onClick={() => socialNetHandler('instagram')}>
                                        <img src={instagramIco} />
                                    </div>
                                    <div className={`social-item ${socialNet === 'tiktok' ? 'active-social' : ''}`} onClick={() => socialNetHandler('tiktok')}>
                                        <img src={tiktokIco} />
                                    </div>
                                    <div className={`social-item ${socialNet === 'vk' ? 'active-social' : ''}`} onClick={() => socialNetHandler('vk')}>
                                        <img src={vkIco} />
                                    </div>
                                    <div className={`social-item ${socialNet === 'youtube' ? 'active-social' : ''}`} onClick={() => socialNetHandler('youtube')}>
                                        <img src={youtubeIco} />
                                    </div>
                                    <div className={`social-item ${socialNet === 'telegram' ? 'active-social' : ''}`} onClick={() => socialNetHandler('telegram')}>
                                        <img src={teleramIco} />
                                    </div>
                                </div>
                                {categoriesNew.length > 0 &&
                                <div style={{ paddingTop : '20px' }}>
                                    <h3 style={{ paddingTop : '0' }}>2. Выберите категорию услуги:</h3>
                                    <div className='categories'>
                                        {categoriesNew.map((item, id) => 
                                        <div 
                                            className='category-item serv-item' 
                                            key={`service-${id}`} 
                                            onClick={(e) => serviceHandler( id, item )}
                                            ref={el => categoriesRef.current[id] = el}
                                            >
                                            <span>{item}</span>
                                        </div>
                                        )}
                                    </div>
                                </div>}
                                
                                {servicesNew.length > 0 && 
                                <div ref={servicesListRef} style={{ paddingTop : '90px', marginTop : '-70px' }}>
                                    <h3 style={{ paddingTop : '0' }}>3. Выберите услугу:</h3>
                                    <div className='categories'>
                                        {availableOrderType.map((item, id) => 
                                        <div 
                                            className='category-item' 
                                            onClick={() => finalServiceHandler(id, item)}
                                            ref={el => servicesRef.current[id] = el}
                                            >
                                            <span>{item.name} - <strong>{item.rate} руб. за 1000 шт.</strong></span>
                                            <div className='single-service-description'>
                                                <span>Минимальное количество: <strong>{item.min} шт.</strong></span><br/>
                                                <span>Максимальное количество: <strong>{item.max} шт.</strong></span><br/>
                                                <p style={{ paddingTop : '10px', paddingBottom : 0, marginBottom : 0 }}>{item.description}</p>
                                                <button 
                                                    className='btn btn-payment' 
                                                    style={{ fontSize : '16px', lineHeight : '9px', marginTop : '10px' }}
                                                    onClick={( e ) => completeOrder( e )}
                                                    >Перейти к оформлению</button>
                                            </div>
                                        </div>
                                        )}
                                    
                                    </div>
                                </div>}
                                
                                {serviceId !== null &&
                                <>
                                <div className="wrapper-input" style={{ paddingTop : '90px', marginTop : '-60px' }} ref={finalForm}>
                                    <h3 style={{ paddingTop : '0' }}>4. Укажите ссылку:</h3>
                                    {/* <label htmlFor="url"><strong>Ссылка</strong></label> */}
                                    <div className="input input--url">
                                        <input type="text" name="url" id="url" onChange={linkHandler} value={link}/>
                                    </div>
                                </div>

                                <div className="wrapper-input">
                                    <h3 style={{ paddingTop : '0' }}>5. Укажите количество:</h3>
                                    {/* <label htmlFor="quantity"><strong>Количество</strong></label> */}
                                    <div className="input input--numb">
                                        <input type="text" name="quantity" id="quantity" style={{borderColor : available ? '#5C7EF4' : 'red'}} onChange={quantityHandler} value={orderState.quantity}/>
                                        <div className="input-btns"></div>
                                    </div>
                                </div>

                               <PromoCode setAppliedCouponValue={setAppliedCouponValue}/>

                                <div style={{ color : 'red', fontWeight : 'bold' }}>
                                    {!available && 'Сумма заказа больше доступного остатка на счете. Пополните баланс.'}
                                </div>

                                <div className="bottom-line">
                                    <OrderPrice orderState={orderState} appliedCouponValue={appliedCouponValue}/>
                                    <button 
                                        className="btn btn-payment" 
                                        onClick={(e) => createOrderHandler(e)} 
                                        style={{ cursor : btnLoading ? 'progress' : 'pointer'}}
                                        disabled={ btnLoading ? true : false } >
                                        <img src={windows} alt="окна" width="22" height="20"/>
                                        <span>Разместить заказ</span>
                                    </button>
                                </div>
                                </>}
                            </form>
                        </div>
                    </div>
    )
}