export const Payments = ({value, status, created_at, itemId, notice, total}) => {
    
    const number = total - itemId 
//#ffb200
    return(
        <tr>
            <td>{number}</td>
            <td>{value} ₽</td>
            <td>{status === 'await' ? <span className="pending">Ожидает зачисления</span> : status === 'canceled' ? <span  className="canceled">Платеж отменен</span> :  <span  className="accrued">Зачислен на счет</span>}</td>
            <td>{created_at}</td>
            <td>{notice}</td>
        </tr>

    )
}