import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { HttpContext } from '../../context/HttpContext'
import { useHttp } from '../../hooks/http.hook'

export const ReferralsList = () => {

    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const { loading, error, request } = useHttp()

    const [ referrals, setReferalls ] = useState([])

    const getReferrals = async (e) => {
        try {
            const response = request( host.url+'/referrals', 'POST', { user : auth.userId },{ 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` })
            response.then(data => {  
                console.log(data)
                if( data.success ) {
                    setReferalls( data.data )
                }
                
            })
        } catch(e) { console.log(e) }
    }

    useEffect(() => {
        getReferrals()
    }, [])

    return(
        <div className='referals-list'>
            <h3>Cписок рефералов</h3>
            {referrals.length === 0 ?
            <p>Список рефералов пуст</p>
            :
            referrals.map(( item, id ) => 
            <div className='referal-item' key={`ref-${id}`}>
                <span>{id + 1}. </span>
                <span>{item.name}</span>
            </div>
            )}
        </div>
    )
}