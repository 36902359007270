import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { NavLink } from 'react-router-dom'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


import { HttpContext } from '../context/HttpContext'
import { useToasts } from 'react-toast-notifications'

import logo from '../assets/images/logo-action.svg'
import eMailIcon from '../assets/images/svg/emailicon.svg'
import { SubmitCaptcha } from '../components/SubmitCaptha';


export const AuthPage = ()=> {
    const { addToast } = useToasts()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const { loading, error, request } = useHttp()

    const [form, setForm] = useState({
        email: '', password: ''
    })

    const changeHandler = event => {
        setForm({ ...form, [event.target.name] : event.target.value })
    }

    const loginHandler = async (e) => {
        e.preventDefault()
        if(form.email.length === 0 || form.password.length === 0) {
            addToast('Все поля являются обязательными для заполнения!', { appearance : 'warning', autoDismiss : true })
            return
        }
        try {
            const response = request( host.url+'/login', 'POST', { ...form })
            response.then(data => {  
                if(data){
                    if(data.message === 'Bad creds') {
                        addToast('Неверные E-mail или пароль.', { appearance : 'error', autoDismiss : true })
                    } else {
                        auth.login(data.token, data.user.name, data.user.id, data.user.user_id, data.user.email_verified_at)    
                    }
                } else {
                    addToast('Что-то пошло не так. Пожалуйста повторите попытку.', { appearance : 'error', autoDismiss : true })
                }
                
            })
        } catch(e) { console.log(e) }
    }

    

    return(
        <section className="enter">
            <div className="enter__wrap">
                <div className="enter__inner">
                    <div className="form-box">
                        <object className="logo-view" type="image/svg+xml" width="170" height="46" data={logo}></object>   
                        <span className="enter__value">Вход</span>
                        <form className="enter-form">
                            <div className="enter-form__wrap">
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="email" name="email" placeholder="youremail@site.com" onChange={changeHandler}/>
                                </div>
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="password" name="password" placeholder="Введите пароль" onChange={changeHandler}/>
                                </div>
                                <div className="enter-form__line">
                                    <div className="checkbox">
                                        <input className="checkbox__input" type="checkbox" id="checkbox_1"/>
                                        <label className="checkbox__label" htmlFor="checkbox_1">Запомнить меня</label>
                                    </div>
                                    <NavLink className="enter__link" to="/recover">Напомнить пароль</NavLink>
                                </div>
                            </div>
                            <button 
                                className="action-btn"
                                onClick={(e) => loginHandler(e)}
                                disabled={ loading ? true : false }
                                style={{ cursor : loading && 'progress' }}
                                >Вход</button>
                           <GoogleReCaptchaProvider reCaptchaKey="6Lfr3oUdAAAAADhZpqBVAccfv8F3NmCSl3Qm1yPX">
                                {/* <SubmitCaptcha/> */}
                            </GoogleReCaptchaProvider>
                        </form>
                        <p className="question-user">
                            Еще не зарегистрированы?  <NavLink to="/register">Регистрация</NavLink>
                        </p>
                        <p className="question-user">
                            <a href="https://instantbooster.ru" target="_blank">instantbooster.ru</a>
                        </p>
                        
                    </div>
                    
                </div>
            </div>
        </section>
    )
}