import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'
import { OrderItem } from '../components/OrderItem'
import { Footer } from '../components/Footer'
import { OrdersTableHead } from '../components/orders/OrdersTableHead'
import ListOrders from '../assets/svg/list-orders.svg'
import loop from '../assets/svg/loop-g.svg'


export const OrdersPage = () => {

    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const { loading, request } = useHttp()

    const [ orders, setOrders ] = useState([])
    const [ displayedOrders, setDisplayedOrders ] = useState([])
    
    const getOrders = async () => {
        try {
            const response = request( host.url+'/getorders', 'POST', { client_id : auth.userId }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                setOrders(data.data)
                setDisplayedOrders(data.data)
            })
          } catch(e) { console.log(e) }
    }

    useEffect(() => {
        async function fetchData() {

            await getOrders();
        }
        fetchData();
        
    }, [ auth.userId ])


    const tabHandler = (e, tab) => {
        const el = document.getElementsByClassName('tab-switcher')
        Array.from(el).map(item => {
            item.classList.remove('is-active')
        })
        e.target.parentElement.classList.add('is-active')
       
        let tmp = []
        switch(tab){
            case 'all':
                tmp = orders
                break
            case 'pending' :
                orders.map(item => { 
                    item.status === 'Pending' && tmp.push(item)
                })   
                break
            case 'progress' :
                orders.map(item => { 
                    item.status === 'In progress' && tmp.push(item)
                })
                break
            case 'complete' :
                orders.map(item => { 
                    item.status === 'Completed' && tmp.push(item)
                })
                break
            case 'canceled' :
                orders.map(item => { 
                    item.status === 'Canceled' && tmp.push(item)
                })
                break
            case 'processing' :
                orders.map(item => { 
                    item.status === 'Processing' && tmp.push(item)
                })
                break
        }
        setDisplayedOrders(tmp)
    }

    const [ ordersSearch, setOrdersSearch ] = useState('')

    const [ foundOrders, setFoundOrders ] = useState([])

    const [ notFound, setNotFound ] = useState( false )

    const ordersSearchHandler = ( e ) => {

        setOrdersSearch( e.target.value )

        if(e.target.value === '') {
            
            setFoundOrders([])

            return

        }

        let tmp = []

        displayedOrders.map(item => {

            if( item?.link?.toLowerCase().includes( e.target.value.toLowerCase() ) || item?.order_title?.toLowerCase().includes( e.target.value.toLowerCase() ) ){

                tmp.push( item )

            }

        })

        if(tmp.length === 0) {

            setNotFound(true)

        } else {

            setNotFound(false)

        }

        setFoundOrders( tmp )

    }



    return(
        <section className="content" style={{ filter : loading ? 'blur(2px)' : '' }}>
           <div className="inner">   
            <div className="widget accent">
                <div className="widget-head">
                    <img className="widget-head__icon" src={ListOrders} alt="Добавить" width="20" height="20" />
                    <span className="widget-head__title">Список заказов</span>
                </div>
                <div className="widget-content">
                    <div className="sort-table">
                        <div className="sort-table__row">
                            <ul className="sort-table-list">
                                <li onClick={(e) => tabHandler(e, 'all')} className="tab-switcher is-active"><a >Все</a></li>
                                <li onClick={(e) => tabHandler(e, 'pending')} className="tab-switcher"><a >Ожидание</a></li>
                                <li onClick={(e) => tabHandler(e, 'progress')} className="tab-switcher"><a >В работе</a></li>
                                <li onClick={(e) => tabHandler(e, 'complete')} className="tab-switcher"><a >Завершен</a></li>
                                <li onClick={(e) => tabHandler(e, 'canceled')} className="tab-switcher"><a >Отменен</a></li>
                                <li onClick={(e) => tabHandler(e, 'processing')} className="tab-switcher"><a >В очереди</a></li>
                            </ul>
                            <form className="search-form search-form--gray" 
                                // style={{ opacity : 0, zIndex : -1 }}
                                >
                                <div className="field-wrap">
                                     <input 
                                        type="text" 
                                        placeholder="Поиск по заказам" 
                                        value={ordersSearch} 
                                        style={{ color : '#2E2E2E' }}
                                        onChange={( e ) => ordersSearchHandler( e )} />
                                     <button className="search-form__btn btn" disabled style={{ cursor : 'initial' }}>
                                         <img src={loop} alt="Поиск" width="16" height="16px" />
                                     </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {!loading&&

                    foundOrders.length === 0 && !notFound ?
                    <div className="wrapper-table-large">
                        <table className="large center">
                            {displayedOrders.length !== 0 ?
                            <>
                            <OrdersTableHead/>
                            <tbody>
                                {displayedOrders.slice(0).reverse().map(item => <OrderItem {...item} key={item.id} />)}
                            </tbody>
                            </>
                            :
                            ''
                            }
                        </table>
                    </div>
                    :
                    !notFound ?
                    <div className="wrapper-table-large">
                        <table className="large center">
                            
                            <>
                            <OrdersTableHead/>
                            <tbody>
                                {foundOrders.slice(0).reverse().map(item => <OrderItem {...item} key={item.id} />)}
                            </tbody>
                            </>
                            
                           
                        </table>
                    </div>
                    :
                    <h3>По вашему запросу ничего не найдено...</h3>  
                    }
                </div>
            </div>
           </div>
           <Footer/>
       </section>
    )
}