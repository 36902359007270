import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AuthPage } from './pages/AuthPage'
import { FaqPage } from './pages/FaqPage'
import { MainPage } from './pages/MainPage'
import { OrdersPage } from './pages/OrdersPage'
import { PaymentPage } from './pages/PaymentPage'
import { RegisterPage } from './pages/RegisterPage'
import { TransactionsPage } from './pages/TransactionsPage'
import { SupportPage } from './pages/SupportPage'
import { PricesPage } from './pages/PricesPage'
import { PersonalPage } from './pages/PersonalPage'
import { PasswordRecoveryPage } from './pages/PasswordRecoveryPage'
import { ReferalPage } from './pages/ReferalPage'
import { PayoutPage } from './pages/PayoutPage'
import { NewsPage } from './pages/NewsPage'




export const UseRoutes = isAuthenticated => {

    if(isAuthenticated) {
        if(window.location.pathname === '/register') {
            window.location.pathname = '/'
        }
        return(
            <Switch>
                <Route path="/" exact>
                    <MainPage />
                </Route>
                <Route path="/order" exact>
                    <MainPage />
                </Route>
                <Route path="/faq" exact>
                    <FaqPage />
                </Route>
                <Route path="/orders" exact>
                    <OrdersPage/>
                </Route>
                <Route path="/payment" exact>
                    <PaymentPage />
                </Route>
                <Route path="/transactions" exact>
                    <TransactionsPage/>
                </Route>
                <Route path="/support" exact>
                    <SupportPage />
                </Route>
                <Route path="/prices" exact>
                    <PricesPage/>
                </Route>
                <Route path="/personal" exact>
                    <PersonalPage/>
                </Route>
                <Route path="/referal" exact>
                    <ReferalPage/>
                </Route>
                <Route path="/payout" exact>
                    <PayoutPage/>
                </Route>
                <Route path="/news" exact>
                    <NewsPage/>
                </Route>
            </Switch>
        )
    }
    

    return(
        <Switch>
            <Route path="/" exact>
                <AuthPage />
            </Route>
            <Route path="/register" exact>
                <RegisterPage />
            </Route>
            <Route path="/recover" exact>
                <PasswordRecoveryPage/>
            </Route>
        </Switch>
    )
}