import React, { useContext, useState } from 'react'

import { Footer } from '../components/Footer'
import wallet from '../assets/svg/wallet.svg'
import { AuthContext } from '../context/AuthContext'
import { ReferralsList } from '../components/referrals-list/ReferralsList'


export const ReferalPage = () => {

    const [ copied, setCopied ] = useState( false )

    const auth = useContext( AuthContext )

    const handleCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/register/?r=${auth.referal}`)
        setCopied( true )
    }

    return(
        <section className="content" >
            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <img className="widget-head__icon" src={wallet} alt="Вопросы" width="20" height="20" />
                        <span className="widget-head__title">Реферальная программа</span>
                    </div>
                    <div className="widget-content">
                        <h3 style={{ paddingBottom : '20px', paddingTop : '0', marginTop: '0' }}>Делитесь своей реферальной ссылкой и зарабатывайте 10% от каждого пополнения реферала!</h3>
                        <div className='referal-link'>
                            <input value={`${window.location.origin}/register/?r=${auth.referal}`} disabled/>
                            <button 
                                onClick={() =>  handleCopy()}
                                className='btn btn-payment'>
                                    {! copied ?
                                    'Копировать ссылку' :
                                    'Ссылка скоприрована'}
                            </button>
                        </div>
                        <ReferralsList/>
                    </div>    
                </div>
            </div>
            <Footer/>
        </section>
    )
}