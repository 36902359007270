import React, { useContext, useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import diaologImg from '../../assets/images/svg/dialog-tiket.svg'
import { AuthContext } from "../../context/AuthContext"
import { HttpContext } from "../../context/HttpContext"
import { useHttp } from "../../hooks/http.hook"


export const SupportAnswer = ({ setShowAnswer, showAnswer, openedTicket }) => {

    const { addToast } = useToasts()
    const { request, loading } = useHttp()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const [ dialog, setDialog ] = useState([])

    const [ branchAnswer, setBranchAnswer ] = useState('')

    const [ dialogLoading, setDialogLoading ] = useState( true )

    const getDialog = async () => {
        try{
            const response = request( host.url+'/supportdialog', 'POST', { action : 'getDialog', userId : auth.userId, parentMessage : openedTicket.ticket_id },
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {

                setDialog( data.data )
                setDialogLoading( false )
                
            })
        } catch(e){ console.log(e) }
    }

    useEffect(() => {
        getDialog()
    }, [ openedTicket ])

    const submitNewTicket = async (e) => {
        e.preventDefault()
        if(branchAnswer.length === 0){
            addToast('Сообщение не может быть пустым.', { appearance : 'warning', autoDismiss : true })
            return
        }
        try {
            // console.log(dialog[0].message_id)
            const response = request( host.url+'/supportdialog', 'POST', 
            { action : 'saveMessageInDialog', userId : auth.userId, message : branchAnswer, mainMessage : openedTicket.ticket_id  }, 
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {

                addToast('Ваша заявка успешно отправлена. Ожидайте ответа специалиста службы поддержки.', { appearance : 'success', autoDismiss : true })
                setDialog( data.data )
                setBranchAnswer('')
                
            })
        } catch(e) { console.log(e) }
    }

    return(
        <div className={`inner__col-6 widget ${showAnswer ? 'width-100' : ''}`}>
            <div className="widget-head">
                <div>
                    <img className="widget-head__icon" src={diaologImg} alt="тикеты" width="20" height="20"/>
                    <span className="widget-head__title">Тикет №{openedTicket.ticket_id}</span>
                </div>
                
                <span onClick={() => setShowAnswer(false)}>Закрыть [ х ]</span>
            </div>
            {dialogLoading ? 
            <div className="widget-content">
                <h4>Загрузка истории переписки...</h4>
            </div>
            :
            <div className="widget-content">
                <div className="message">
                    <h4 style={{ marginTop : 0 }}>{openedTicket.topic}</h4>
                    {dialog.map((item, id) => 
                    <div style={{ marginTop : '20px' }}>
                        <span>{item.user_message}</span>
                        {item.support_answer !== null &&
                        <div style={{ padding : '20px', background : "#f0f0f0", margin : '20px 0 0' }}>
                            <bold style={{ fontWeight : 'bold' }}>Ответ: </bold>{item.support_answer.message}
                        </div>}
                    </div>
                    )}
                </div>   
                <div className="branch-answer">
                    <h4 style={{ marginTop : 0 }}>Сообщение:</h4>
                        <textarea
                            value={branchAnswer}
                            onChange={(e) => setBranchAnswer(e.target.value) }></textarea>
                        <div style={{ display : 'flex', alignItems : 'center', justifyContent : 'flex-end' }}>
                            {/* <div style={{ display : 'flex', alignItems : 'center' }}>
                                <input style={{ width : 'auto', marginRight : '10px' }} type="checkbox"/>
                                <span style={{ display : 'block' }}>Уведомить об ответе от службы поддержки по Email</span>
                                
                            </div> */}
                            
                            
                            <button 
                                        className="form-btn widget-btn" 
                                        onClick={(e) => submitNewTicket(e)} 
                                        style={{ cursor : loading ? 'progress' : 'pointer' }}
                                        disabled={ loading ? true : false }
                                        >
                                        <span>Отправить</span>
                                    </button>
                            
                        </div>
                        
                </div>            
            </div>}  
        </div>
    )
}