import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useHttp } from '../hooks/http.hook'
import { NavLink, Router, Redirect, useHistory } from 'react-router-dom'
// import ibLogo from '../img/Tok_Booster_logo.svg'
import { useToasts } from 'react-toast-notifications'
import { HttpContext } from '../context/HttpContext'

import logo from '../assets/images/logo-action.svg'
import eMailIcon from '../assets/images/svg/emailicon.svg'


export const RegisterPage = () => {
    const { addToast } = useToasts()
    const history = useHistory()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const [ referral, setReferral ] = useState( null )

    const { loading, error, request } = useHttp()

    const [form, setForm] = useState({
        name: '', email: '', password: '', c_password: ''
    })

    const changeHandler = event => {
        setForm({ ...form, [event.target.name] : event.target.value })
    }

    const loginHandler = async (e) => {
        e.preventDefault()
        if(form.email.length === 0 || form.password.length === 0 || form.name.length === 0) {
            addToast('Все поля являются обязательными для заполнения!', { appearance : 'warning', autoDismiss : true })
            return
        }
        // if(form.password != form.c_password) {
        //     addToast('Пароли не совпадают!', { appearance : 'warning', autoDismiss : true })
        //     return
        // }

        try {
            const response = request(host.url+'/register', 'POST', { ...form, referral : referral })
            response.then(data => {
                if(data.message.error === 'Unregistered') {
                    addToast(data.data, { appearance : 'error', autoDismiss : true })
                } else {
                    auth.login(data.data.token, data.data.name, data.data.id, data.data.user_id, data.role)
                    history.push('/')
                }
            })
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {

        if( window.location.search !== undefined ){
            const ref = window.location.search.slice( 3 )
            setReferral( ref )

            localStorage.setItem('ref', ref)
        }
        
    }, [  ])

    return(
        <section className="enter">
            <div className="enter__wrap">
                <div className="enter__inner">
                    <div className="form-box">
                        <object className="logo-view" type="image/svg+xml" width="170" height="46" data={logo}></object>   
                        <span className="enter__value">Регистрация</span>
                        <form className="enter-form">
                            <div className="enter-form__wrap">
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="text" name="name" placeholder="Имя" onChange={changeHandler}/>
                                </div>
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="email" name="email" placeholder="youremail@site.com" onChange={changeHandler}/>
                                </div>
                                <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="password" name="password" placeholder="Введите пароль" onChange={changeHandler}/>
                                </div>
                                {/* <div className="wrapper-input wrapper-input--action-login">
                                    <img className="icon-field" src={eMailIcon} alt="" width="20" height="16"/>
                                    <input type="password" name="c_password" placeholder="Повторите пароль" onChange={changeHandler}/>
                                </div> */}
                                
                            </div>
                            <button 
                                className="action-btn"
                                onClick={(e) => loginHandler(e)}
                                style={{ cursor : loading ? 'progress' : 'pointer'}}
                                disabled={ loading ? true : false }
                                >Зарегистрироваться</button>
                        </form>
                        <p className="question-user">
                            Уже есть аккаунт?  <NavLink to="/">Вход</NavLink>
                        </p>
                        <p className="question-user">
                            <a href="https://instantbooster.ru" target="_blank">instantbooster.ru</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}