import React, { useState, useContext, useEffect } from 'react'
import { Footer } from '../components/Footer'
import wallet from '../assets/svg/wallet.svg'
import parse from 'html-react-parser'
import { SingleNews } from '../components/single-news/SingleNews'
import { AuthContext } from '../context/AuthContext'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'

export const NewsPage = () => {

    const auth = useContext(AuthContext)

    const host = useContext(HttpContext)

    const { request } = useHttp()

    const [ news, setNews ] = useState([])

    const [ showSingle, setShowSingle ] = useState( false )

    const [ selected, setSelected ] = useState()

    const getNews = async () => {
        try{
            const response = request( host.url+'/news', 'POST', { userId : auth.userId  },
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                console.log(data)
                setNews( data.data )
            })
        } catch(e){ console.log(e) }
    }

    useEffect(() => {
        getNews()
    }, [ ])

    const handleAction = async ( id ) => {
        try{
            const response = request( host.url+'/action', 'POST', { userId : auth.userId, action : 'article', news_id : id },
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then( data => {
                // console.log(data)
              
            })
        } catch(e){ console.log(e) }
    }

    const newsHandler = ( id, isUnread ) => {
       
        if(isUnread) {
            handleAction( news[id].id )
            let tmp = news
            tmp[id] = {...tmp[id], isUnread : false}
            setNews( tmp )
        }
        
        setShowSingle( true )
        setSelected( news[id] )
    }

    return(
        <section className="content" >
            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <img className="widget-head__icon" src={wallet} alt="Вопросы" width="20" height="20" />
                        <span className="widget-head__title">Сообщения и новости</span>
                    </div>
                    {!showSingle ?
                    <div className="widget-content no-padding">
                        {news.map((item, id) => 
                        <div className={`news-item ${item.isUnread ? 'unread-item' : ''}`} key={`nw-${id}`} onClick={() => newsHandler( id, item.isUnread )}>
                            <div><strong>{item.title}</strong></div>
                            <div>{parse(item.short)}</div>
                        </div>
                        )}
                    </div>   
                    : 
                    <SingleNews setShowSingle={setShowSingle} {...selected} />}
                </div>
            </div>
            <Footer/>
        </section>
    )
}