import React, { useContext, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'
import { useToasts } from 'react-toast-notifications'
import { Footer } from '../components/Footer'
import wallet from '../assets/svg/wallet.svg'
import mIcon from '../assets/images/svg/m-icon.svg'
import visa from '../assets/images/payment/visa.png'
import qiwi from '../assets/images/payment/qiwi.png'
import youmoney from '../assets/images/payment/y.png'
import ym from 'react-yandex-metrika'

export const PaymentPage = () => {

    const { addToast } = useToasts()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)

    const [ btnLoad, setBtnLoad ] = useState(false)

    const { loading, error, request } = useHttp()

    const [ paymentValue, setPaymentValue ] = useState()

    const [ paymentType, setPaymentType ] = useState('')

    const [ inputClasses, setInputClasses ] = useState('enter-payment')
   
    const paymentValueHandler = event => {
        setPaymentValue(event.target.value)
        setInputClasses('enter-payment')
    }

    const submitPayment = async (e) => {
        e.preventDefault()
        setBtnLoad(true)
        if(paymentValue === undefined) {
            addToast('Поле суммы пополнения не может быть пустым!', { appearance : 'warning', autoDismiss : true })
            return
        }
        try {
            if(paymentType === 'qiwi') {
                const response = request(`${host.url}/payments`, 'POST', { client_id: auth.userId, value: paymentValue }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` })
                response.then(data => {
                   
                    if(data.error) {
    
                    } else {
                        const publicKey = '48e7qUxn9T7RyYE1MVZswX1FRSbE6iyCj2gCRwwF3Dnh5XrasNTx3BGPiMsyXQFNKQhvukniQG8RTVhYm3iPqQ9TFxCSU6k7ADMfDXqcjBfJ4Lvt7QnZVWBVmhz3ZwobKXXBMhzhJVFV57C6L7UL8E4HDPst6jwTCq9vSVznaVtJUBNvmKASShH1avoa4'
                        const successUrl = 'https://app.instantbooster.ru'
                        document.location.href = `https://oplata.qiwi.com/create?publicKey=${publicKey}&billId=${data.data.id}&amount=${paymentValue}&successUrl=${successUrl}&email=support@instantbooster.ru&customFields[paySourcesFilter]=qw,card`
                    }
                    setBtnLoad( false )
                })
            }

            if( paymentType === 'card' || paymentType === 'youmoney' ){
                const response = request(`${host.url}/youcassapayment`, 'POST', { client_id: auth.userId, value: paymentValue }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` })
                response.then(data => {
                   
                    if(data.error) {
                        addToast('Упс... Что то пошло не так. Пожалуйста, попробуйте позже.', { appearance : 'warning', autoDismiss : true })
                    } else {
                        // window.open(data.data.confirmation.confirmation_url, '_blank');
                        ym('reachGoal', 'toPayment', {})
                        document.location.href = data.data.confirmation.confirmation_url
                    }
                    setBtnLoad(false)
                })
            }
            

        } catch(e) { setBtnLoad(false) }
    
    }
    return(
        <section className="content" >
            <div className="inner">
                <div className="widget accent">
                    <div className="widget-head">
                        <img className="widget-head__icon" src={wallet} alt="Вопросы" width="20" height="20" />
                        <span className="widget-head__title">Пополнить счет</span>
                    </div>
                    <div className="widget-content">
                        <h3 style={{ textAlign: 'center', margin: 0, fontWeight: 'normal', fontSize: '18px'}}>Выберите способ оплаты</h3>
                        <ul className="payment-list">
                            <li class="payment-list__item" onClick={() => setPaymentType('youmoney')}>
                                <label class="payment-box" for="ymoney">
                                    <input class="payment-box__check visually-hidden" type="radio" name="payment" id="ymoney" value="ymoney"/>
                                    <div class="payment-box__img">
                                        <img src={youmoney} alt="ymoney" />
                                    </div>
                                    <span class="payment-box__name">Ю-Касса</span>
                                    <span class="payment-box__border"></span>
                                </label>
                            </li>
                            <li className="payment-list__item" onClick={() => setPaymentType('card')}>
                                <label className="payment-box" htmlFor="visa">
                                    <input className="payment-box__check visually-hidden" type="radio" name="payment" id="visa" value="cart"  />
                                    <div className="payment-box__img">
                                        <img src={visa} alt="visa" />
                                    </div>
                                    <span className="payment-box__name">Карта</span>
                                    <span className="payment-box__border"></span>
                                </label>
                            </li>
                            {/* <li className="payment-list__item" onClick={() => setPaymentType('qiwi')}>
                                <label className="payment-box" htmlFor="qiwi">
                                    <input className="payment-box__check visually-hidden" type="radio" name="payment" id="qiwi" value="qiwi" />
                                    <div className="payment-box__img">
                                        <img src={qiwi} alt="qiwi" />
                                    </div>
                                    <span className="payment-box__name">QIWI</span>
                                    <span className="payment-box__border"></span>
                                </label>
                            </li> */}
                        </ul>
                        <div className="box-pay">
                            <div className="box-pay__field">
                                <label htmlFor="">Введите сумму пополнения:</label>
                                <input type="text" value={paymentValue} onChange={(e) => paymentValueHandler(e)} />
                            </div>
                            <button 
                                className="box-pay-btn" 
                                onClick={(e) => submitPayment(e)}
                                style={{ cursor : btnLoad ? 'progress' : 'pointer' }}
                                disabled={btnLoad}
                                >
                                <img src={mIcon} alt="Деньги" />
                                <span>Оплатить</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </section>
    )
}