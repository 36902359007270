import React, { useContext, useState, useEffect } from "react"
import supportIcon from '../assets/images/svg/supp-icon.svg'
import paint from '../assets/images/svg/paint.svg'
import diaolog from '../assets/images/svg/dialog-tiket.svg'
import { useToasts } from "react-toast-notifications"
import { AuthContext } from "../context/AuthContext"
import { HttpContext } from "../context/HttpContext"
import { useHttp } from "../hooks/http.hook"
import { Footer } from '../components/Footer'
import { TicketItem } from "../components/support/TicketItem"
import { SupportAnswer } from "../components/support/SupportAnswer"

export const SupportPage = () => {
    const { addToast } = useToasts()
    const auth = useContext(AuthContext)
    const host = useContext(HttpContext)
    const { request, loading } = useHttp()

    const [tickets, setTickets] = useState([])

    const [ticketContent, setTicketContent] = useState({
        topic   : '',
        orderId : '',
        message : ''
    })

    const changeHandler = (e) => {
        setTicketContent({...ticketContent, [e.target.name] : e.target.value})
    }

    const submitNewTicket = async (e) => {
        e.preventDefault()
        if(ticketContent.topic.length === 0){
            addToast('Укажите тему вашего обращения.', { appearance : 'warning', autoDismiss : true })
            return
        }
        if(ticketContent.message.length === 0){
            addToast('Опишите вашу проблему.', { appearance : 'warning', autoDismiss : true })
            return
        }
        try {
            const response = request( host.url+'/support', 'POST', 
            { action : 'newTicket', userId : auth.userId, ...ticketContent }, 
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                addToast('Ваша заявка успешно отправлена. Ожидайте ответа специалиста службы поддержки.', { appearance : 'success', autoDismiss : true })
                setTickets(data.data)
                setTicketContent({
                    topic   : '',
                    orderId : '',
                    message : '',
                    parentMessage : ''
                })
            })
        } catch(e) { console.log(e) }
    }

    const getTickets = async () => {
        try{
            const response = request( host.url+'/support', 'POST', { action : 'getTickets', userId : auth.userId  },
            { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ${auth.token}` }) 
            response.then(data => {
                setTickets(data.data)
            })
        } catch(e){ console.log(e) }
    }

    useEffect(() => {
        getTickets()
    }, [])

    const [ showAnswer, setShowAnswer ] = useState(false)
    const [ openedTicket, setOpenedTicket ] = useState({})

    return(
        <section className="content">
            <div className="inner">
                <div className="inner__label">
                    <img src={supportIcon} alt="данные"/>
                    <span>Служба поддержки</span>
                </div>
                <div className="inner__row">
                    
                    <div className={`inner__col-4 widget accent add-new-ticket ${showAnswer ? 'width-0' : ''}`}>
                        <div className="widget-head">
                            <img className="widget-head__icon" src={paint} alt="Добавить" width="20" height="20"/>
                            <span className="widget-head__title">Добавить новый тикет </span>
                        </div>
                        <div className="widget-content">
                            <form className="form-tiket">
                                <div className="wrapper-input">
                                    <label htmlFor="user_tiket_theme">Тема</label>
                                    <input type="email" name="topic" placeholder="Укажите тему обращения" onChange={(e) => changeHandler(e)} value={ticketContent.topic}/>
                                </div>
                                {/* <div className="wrapper-input">
                                    <label htmlFor="user_tiket_request">Запрос</label>
                                    <input type="email" name="user_tiket_request" id="user_tiket_request" value="Докрутить"/>
                                </div> */}
                                <div className="wrapper-input">
                                    <label htmlFor="user_tiket_id_order">ID закза</label>
                                    <input type="email" name="orderId" placeholder="Для нескольких заказов разделите их запятыми" value={ticketContent.orderId} onChange={(e) => changeHandler(e)}/>
                                </div>
                                <div className="wrapper-input">
                                    <label htmlFor="user_tiket_description">Описание</label>
                                    <textarea name="message" onChange={(e) => changeHandler(e)} value={ticketContent.message}></textarea>
                                </div>
                                <button 
                                    className="form-btn widget-btn" 
                                    onClick={(e) => submitNewTicket(e)} 
                                    style={{ cursor : loading ? 'progress' : 'pointer' }}
                                    disabled={ loading ? true : false }
                                    >
                                    <span>Отправить</span>
                                </button>
                            </form>
                        </div>  
                    </div>
                    {showAnswer ? 
                    <SupportAnswer showAnswer={showAnswer} setShowAnswer={setShowAnswer} openedTicket={openedTicket}/>
                    :
                    <div className={`inner__col-6 widget ${showAnswer ? 'width-100' : ''}`}>
                        <div className="widget-head">
                            <img className="widget-head__icon" src={diaolog} alt="тикеты" width="20" height="20"/>
                            <span className="widget-head__title">Список тикетов</span>
                        </div>
                        <div className="widget-content">
                            {tickets.length ?
                            <div className="wrapper-table">
                                <table className="even table-ticket">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Дата</th>
                                            <th>Тема</th>
                                            <th>Статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.slice(0).reverse().map((item, id) => <TicketItem {...item} id={id} key={id} setShowAnswer={setShowAnswer} setOpenedTicket={setOpenedTicket}/>)}
                                    </tbody>
                                </table>
                            </div>
                            :
                            loading ?
                            <h3>Загрузка тикетов...</h3>
                            :
                            <h3>У вас нет ни одного тикета</h3>
                            }
                        </div>  
                    </div>}
                </div>
            </div>
            <Footer/>
        </section>
    )
}