import React, { useContext, useState} from 'react'

const DataContext = React.createContext({})

export const useData = () => {
    return useContext(DataContext)
}

export const DataProvider = ({ children }) => {

    const [ data, setData ] = useState({
        isEmailConfirmed : null,
        confirmationHeight: 0,
        mobileMenu : false,
        balance : 0
    })

    return(
        <DataContext.Provider value={{
            app : data,
            setData
        }}>
            { children }
        </DataContext.Provider>
    )
}

